@import './variables';

.select-text.fix-width{
    max-width: 480px;
    min-width: 225px;
    width: 100%;
}

.ng-select {
    &.ng-select-focused {
        .ng-select-container {
            box-shadow: none!important;
        }
    }
    .ng-select-container {
        height: 46px!important;
        width: 100%!important;
        border: none!important;
        padding: 0px 20px!important;
        border-radius: 12px!important;
        background: transparent !important;
        &:hover {
            box-shadow: none!important;
        }
        &:focus {
            box-shadow: none!important;
        }
        .ng-value-container {
            padding-left: 0px !important;
            .ng-placeholder {
                width: 100%;
                text-align: right;
            }
            .ng-value {
                width: 100%;
                .itemValue,
                .itemLabel {
                    width: 50%;
                }
                .itemLabel {
                    text-align: right;
                    // color:#B4B4B4;
                }
            }
        }
        .ng-arrow-wrapper {
            padding-left: 12px;
            padding-right: 0;
            .ng-arrow:before {
                display: contents;
                position: relative;
                font-family: "FontAwesome";
                content: "\f107";
                color: $primary-button-bg-color;
                font-weight: 900;
                font-size: 24px;
                background-image: none;
            }
            .ng-arrow {
                display: contents;
            }
        }
    }
    &.ng-select-opened>.ng-select-container .ng-arrow {
        top: 0!important;
        border-width: 0!important;
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.ng-dropdown-panel {
    .scroll-host {
        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }
    }
    border: none!important;
    box-shadow: none!important;
    background: transparent !important;
    .ng-dropdown-panel-items {
        // padding: 16px 24px!important;
        .ng-option {
            width: 100%;
            padding: 0px 0 !important;
            .optionValue {
                border-radius: 8px;
                width: 100%;
                height: 48px;
                text-align: center;
                margin-bottom: 0;
                padding: 13px;
            }
        }
    }
}

.floatSelect {
    position: relative;
    min-width: 448px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 24px;
    .select-text {
        position: relative;
        font-family: inherit;
        background-color: transparent;
        padding: 0 !important;
        border-bottom: none;
        border-radius: 12px;
        height: 48px;
        ​ &:focus {
            outline: none;
            border-bottom: none; // 1px solid rgba(0,0,0, 0);
            &+.select-label {
                transform: translate(0.75rem, -45%) scale(0.9);
                color: #85868F;
                padding-left: 9px;
                top: 0;
            }
        }
    }
    .select-label {
        position: absolute;
        left: 0;
        top: 5px;
        padding: 8px 20px; //calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
        margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
        font-family: "Asap";
        letter-spacing: 0.5px;
        font-weight: 400;
        line-height: 21px;
        font-size: 16px;
        z-index: -1;
        color: #b4b4b4;
    }
    ​ .select-text:focus~.select-label,
    .labelFloat {
        //, .select-text:valid ~ .select-label {
        transform: translate(0.75rem, -45%) scale(0.9);
        color: #b4b4b4;
        padding: 0px 20px;
        padding-left: 9px;
        top: 0;
    }
    .labelFloat {
        z-index: 1000 !important;
    }
}

// Multiselect

.multiNgSelect{
    .ng-select {
        &.ng-select-focused {
            .ng-select-container {
                box-shadow: none!important;
            }
        }
        .ng-select-container {
            height: unset !important;
            min-height: 48px!important;
            width: 100%!important;
            border: none!important;
            padding: 0px 20px!important;
            border-radius: 12px!important;
            background: transparent !important;
            &:hover {
                box-shadow: none!important;
            }
            &:focus {
                box-shadow: none!important;
            }
            .ng-value-container {
                padding-left: 0px !important;
                .ng-placeholder {
                    width: 100%;
                    text-align: right;
                }
                .ng-value {
                    width: unset !important;
                    .itemValue,
                    .itemLabel {
                        width: 50%;
                    }
                    .itemLabel {
                        text-align: right;
                    }
                }
            }
            .ng-arrow-wrapper {
                padding-left: 12px;
                padding-right: 0;
                .ng-arrow:before {
                    display: contents;
                    position: relative;
                    font-family: "FontAwesome";
                    content: "\f107";
                    color: $primary-button-bg-color;
                    font-weight: 900;
                    font-size: 24px;
                    background-image: none;
                }
                .ng-arrow {
                    display: contents;
                }
            }
        }
        &.ng-select-opened>.ng-select-container .ng-arrow {
            top: 0!important;
            border-width: 0!important;
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        &.ng-select-multiple .ng-select-container .ng-value-container{
            .ng-value{
                background-color: $primary-button-bg-color !important;
                padding: 4px 6px !important;
                color: $primary-button-color;
                .ng-value-icon.left{
                    border-right: 1px solid $primary-button-color !important;
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
        } 
            .ng-placeholder{
                top: 11px !important;
                right: 41px !important;
            }
    }
}