@import './variables';

.accordian-view{
    .fa-angle-right{
        font-size: 20px;
        font-weight: 900;
        color: $primary-button-bg-color;
        cursor: pointer;
        &.to-up{
            transform: rotate(-90deg);
        }
    }
}

.accordian-card {
    background-color: transparent !important;
    &.collapsed {
        .fa-angle-up {
            transform: rotate(180deg);
            padding-bottom: 0px;
            margin-top: 3px !important;
        }
        .fa-angle-down {
            transform: rotate(180deg);
            padding-bottom: 0px;
        }
    }
    .lable-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 20px;
        cursor: pointer;
        .icon-i {
            height: 28px;
            color: $primary-button-bg-color;
            margin: 1px 0px 0px 10px;
            .fa-angle-up {
                font-size: 20px;
                font-weight: 800;
                margin-top: 0;
            }
            .fa-angle-down {
                font-size: 20px;
                font-weight: 800;
                margin-top: 0;
            }
        }
    }
}