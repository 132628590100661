// Pagination CSS
    
pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 32px
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
    background: transparent !important;
}

.ngx-pagination .current {
    border-radius: 8px;
    color: $highlight-text-color !important
}