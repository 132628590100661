@import url('https://fonts.googleapis.com/css2?family=Asap:wght@400;500;700&display=swap');
$custom: #71b6f9; //lighter blue
// Color variables
$white: #ffffff;
$primary: #188ae2; //light blue
$secondary: #727b84; // mid gray
$success: #00a510; // success green
$info: #35b8e0; //blueish
$warning: #f9c851; // yellowish warning
$danger: #FC3131;
$purple: #5b69bc;
$pink: #ff8acc;
$inverse: #3b3e47;
$muted: #3A3A3A;
$light: #eeeeee;
$dark: #435966;
$dark2:#1E1F23;
$lightdark3: #333333;
$lightdark4: #444444;
$lightdark5: #555555;
$lightdark7: #797979;
$light5: #ebeff2;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark-alt: #cccccc;
$lightdark: #f4f8fb;
$lightgray: #7a8c9a;
$lightdark8:#6a6a6a;
$lightdark9:#b4b4b4;
// Previous Theme
$orange: #00D4FF;
$headingColor: #6A6A6A;
$inputTextColor: #6A6A6A;
$backButton: #6A6A6A;
$hintColor: #B4B4B4;
$textButton: #00D4FF;
$secondaryButton: #B4B4B4;
$darkteel: #2F3E47;
$backgroundInput: #F2F2F2;
// Light Mode New Theme SetUp
$card-heading-color: var(--card-heading-color);
$page-heading-color: var(--page-heading-colo);
$hint-color: var(--hint-color);
$user-input-color: var(--user-input-color);
$placeholder: var(--placeholder);
$hint-small-color: var(--hint-small-color);
$primary-highlight-box-bg-color: var(--primary-highlight-box-bg-color);
$secondary-highlight-box-bg-color: var(--secondary-highlight-box-bg-color);
$primary-card-bg-color: var(--primary-card-bg-color);
$secondary-card-bg-color: var(--secondary-card-bg-color);
$radio-button-bg-color: var(--radio-button-bg-color);
$inactive-radio-button-bg-color: var(--inactive-radio-button-bg-color);
$mapping-card:var(--mapping-card);
$line-map-dot:var(--ine-map-dot);
$ng-select-color:var(--ng-select-color);
$ng-select-bg-color:var(--ng-select-bg-color);
$sidebar-active-color: var(--sidebar-active-color);
$sidebar-active-bg-color: var(--sidebar-active-bg-color);
$sidebar-menu-bg-color:var(--sidebar-menu-bg-color);
$kyc-stroke:var(--kyc-stroke);
$input-border-color:#e5e5e5;
// Dark Mode New Theme SetUpvar(--)
$dark-card-heading-color:var(--dark-card-heading-color);
$dark-page-heading-color: var(--dark-page-heading-color);
$dark-hint-color: var(--dark-hint-color);
$dark-user-input-color: var(--dark-user-input-color);
$dark-placeholder: var(--dark-placeholder);
$dark-hint-small-color: var(--dark-hint-small-color);
$dark-primary-highlight-box-bg-color: var(--dark-primary-highlight-box-bg-color);
$dark-secondary-highlight-box-bg-color: var(--dark-secondary-highlight-box-bg-color);
$dark-primary-card-bg-color: var(--dark-primary-card-bg-color);
$dark-secondary-card-bg-color: var(--dark-secondary-card-bg-color);
$dark-radio-button-bg-color: var(--dark-radio-button-bg-color);
$dark-inactive-radio-button-bg-color: var(--dark-inactive-radio-button-bg-color);
$dark-mapping-card:var(--dark-mapping-card);
$dark-line-map-dot:var(--dark-line-map-dot);
$dark-ng-select-color:var(--dark-ng-select-color);
$dark-ng-select-bg-color:var(--dark-ng-select-bg-color);
$dark-sidebar-active-color: var(--dark-sidebar-active-color);
$dark-sidebar-active-bg-color: var(--dark-sidebar-active-bg-color);
$dark-sidebar-menu-bg-color:var(--dark-sidebar-menu-bg-color);
$dark-kyc-stroke:var(--dark-kyc-stroke);
$dark-input-border-color:rgba(0, 0, 0, 0.2);
// Common Color Code
$back-button-color:var(--back-button-color);
$primary-button-color: var(--primary-button-color);
$primary-button-bg-color: var(--primary-button-bg-color);
$inactive-primary-button-color: var(--inactive-primary-button-color);
$inactive-primary-button-bg-color: var(--inactive-primary-button-bg-color);
$secondary-button-color: var(--secondary-button-color);
$chips-button-color:var(--chips-button-color);
$primary-text-button-color: var(--primary-text-button-color);
$icon-button-color: var(--icon-button-color);
$icon-button-bg-color: var(--icon-button-bg-color);
$text-success-color:var(--text-success-color);
$text-danger-color: var(--text-danger-color);
$text-warning-color: var(--text-warning-color);
$red-alert-color: var(--red-alert-color);
$red-alert-button-color:var(--red-alert-button-color);
$red-alert-button-bg-color:var(--red-alert-button-bg-color);
$special-button-color: var(--special-button-color);
$special-button-bg-color: var(--special-button-bg-color);
$radio-button-color: var(--radio-button-color);
$inactive-radio-button-color: var(--inactive-radio-button-color);
$filter-cancel-color: var(--filter-cancel-color);
$filter-cancel-bg-color: var(--filter-cancel-bg-color);
$filter-button-color: var(--filter-button-color);
$filter-button-border: var(--filter-button-border);
$secondary-text-button-color: var(--secondary-text-button-color);
$inactive-secondary-text-button-color: var(--inactive-secondary-text-button-color);
$inactive-primary-text-button-color:var(--inactive-primary-text-button-color);
$toggle-button-color: var(--toggle-button-color);
$inactive-toggle-button-color: var(--inactive-toggle-button-color);
$special-text-color: var(--special-text-color);
$highlight-text-color:var(--highlight-text-color);
$transparent-bg: var(--transparent-bg);
// Width variables
$width: 100%;
// Height variables
$height: 100%;
// Font size 
$small-font-size: 12px;
$base-font-size: 14px;
$primary-font-size: 16px;
$secondary-font-size: 18px;
$tertiary-font-size: 20px;
// Border Radius
$primary-border-radius: 12px;
$secondary-border-radius: 4px;
$chips-border-radius: 24px;
// Leftbar background 
$bg-leftbar: #252f35;
//Font variables
$font-primary: 'Asap' !important;
$font-secondary: 'Asap Medium' !important;
$font-tertiary: 'Asap Bold' !important;
// Variables for Height
$button-height-46: 46px;
$button-height-48: 48px;
$button-height-36: 36px;
$button-height-32: 32px;
