// Common Tags
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
pre {
    margin: 0;
}

// Cards
.card-box,
.primary-card,
.secondary-card,
.highlight-card,
.modal-primary-card {
    border-radius: $primary-border-radius;
    padding: 24px;
}

// Scroll bar

.card-is-scrollable{
    max-height: 100vh;
    overflow-y: auto;

    &.activity-log{
        height: 90vh;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar{
        width: 5px;
    }
}

.info-primary-card {
    border-radius: 12px;
    min-height: 48px;
    padding: 12px 24px;
    min-width: 256px;
    max-width: 448px;
  
    &.no-max-width {
      max-width: unset !important;
    }
  }

.mapping-card {
    min-height: 48px;
    max-height: max-content;
    border-radius: 8px;
    font-size: $primary-font-size;
    max-width: 100%;
}

// Tabs

.details-tabs{
    display: flex;
    flex-wrap: wrap;
    .item{
        font-size: $primary-font-size;
        font-family: $font-primary;
        height: 36px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 0px 24px;
        cursor: pointer;
        border-radius: 12px;
        margin-right: 16px;

        &.active{
            border: 2px solid $highlight-text-color !important;
            box-shadow: 0px 0px 6px rgba(0, 189, 227, 0.5);
        }
    }
}

// Text Headings   ** Please use only heading tag to apply these classes
.card-headings {
    // h5
    font-family: $font-secondary;
    font-size: $secondary-font-size;
}

.page-headings {
    // h4
    font-family: $font-secondary;
    font-size: $tertiary-font-size;
}

// Buttons  ** Button classes should be like this class="btn primary-button"
.btn {
    border-radius: $primary-border-radius;
    height: 48px;
    min-width: 168px;
    padding: 0px 24px;
    cursor: pointer;
    border: none;
    font-family: $font-secondary;
    font-size: $primary-font-size;
    box-shadow: none !important;
}

.primary-button {
    color: $primary-button-color !important;
    background-color: $primary-button-bg-color !important;
    &:disabled {
        background-color: $inactive-primary-button-bg-color !important;
        color: $inactive-primary-button-color !important;
    }
}

.secondary-button {
    color: $secondary-button-color !important;
    background-color: $transparent-bg;
    border: 2px solid $secondary-button-color !important;
}

.outline-button {
    color: $primary-button-bg-color!important;
    background-color: $transparent-bg;
    border: 2px solid $primary-button-bg-color !important;

    &:disabled{
        cursor: not-allowed;
        color: $primary-button-bg-color!important;
        background-color: $transparent-bg;
        border: 2px solid $primary-button-bg-color !important;
    }
}

.chips-button {
    color: $chips-button-color !important;
    background-color: $transparent-bg;
    border: 2px solid $chips-button-color !important;
    border-radius: $chips-border-radius;
    font-size: $primary-font-size;
}

.chips-button-sm {
    color: $chips-button-color !important;
    background-color: $transparent-bg;
    border: 1px solid $chips-button-color !important;
    border-radius: $chips-border-radius;
    padding: 8px 8px;
    min-width: 0px;
    height: 36px;
    font-size: $primary-font-size;
}

.red-alert-button {
    font-size: $primary-font-size;
    color: $red-alert-button-color !important;
    background-color: $red-alert-button-bg-color;
}

.special-button {
    font-size: $primary-font-size;
    color: $special-button-color !important;
    background-color: $transparent-bg;
}

.filter-button {
    color: $filter-button-color !important;
    background-color: $transparent-bg;
    border: 2px solid $filter-button-border !important;
}

.filter-cancel-button {
    color: $filter-cancel-color !important;
    background-color: $filter-cancel-bg-color;
}

.primary-text-button {
    color: $primary-text-button-color !important;
    font-size: $primary-font-size;
    border-radius: 0px;
    background-color: $transparent-bg;
    border: none !important;
    span {
        border-bottom: 2px dashed $primary-text-button-color;
    }
    &:disabled {
        color: $inactive-primary-text-button-color;
        background-color: $transparent-bg;
        span {
            border-bottom: 2px dashed $inactive-primary-text-button-color;
        }
    }
}

.secondary-text-button {
    font-size: $primary-font-size;
    color: $secondary-text-button-color !important;
    border-radius: 0px;
    background-color: $transparent-bg;
    border: none !important;
    span {
        border-bottom: 2px dashed $secondary-text-button-color;
    }
    &:disabled {
        color: $inactive-secondary-text-button-color;
        background-color: $transparent-bg;
        span {
            border-bottom: 2px dashed $inactive-secondary-text-button-color;
        }
    }
}

.text-transparent-button{
    min-width: 120px;
    height: 36px;
    white-space: nowrap;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    font-size: $primary-font-size;

    &.secondary{
        color: $primary-button-bg-color;
        cursor: pointer;
    }
}

// Texts   ** Please use only para tag to apply these classes
.hint-text {
    font-size: $primary-font-size;
    font-family: $font-primary;
}

.hint-text-small {
    font-size: $small-font-size !important;
    font-family: $font-primary;
}

.highlight-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $highlight-text-color !important;
}

.special-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $special-text-color;
}

.red-alert-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $red-alert-color;
}

.user-input-text {
    font-size: $primary-font-size;
    font-family: $font-primary;
}

.pre-text{
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $dark-user-input-color;
}

.success-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $text-success-color !important;
}

.success-bg-color{
    background-color: $text-success-color !important;
}

.warning-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $text-warning-color !important;
}

.warning-bg-color{
    background-color: $text-warning-color !important;
}

.danger-text {
    font-size: $primary-font-size;
    font-family: $font-secondary;
    color: $text-danger-color !important;
}

.danger-bg-color{
    background-color: $text-danger-color !important;
}

// Badegs

.risk-status-badge {
    cursor: default;
    height: 36px;
    white-space: nowrap;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    border-radius: 12px;
    max-width: fit-content;
    font-family: $font-secondary;
    font-size: $primary-font-size;

    &.secondary{
        cursor: pointer;
    }
  }

// Highlighted Boxes
.primary-highlight-box {
    height: 48px;
    border-radius: $primary-border-radius;
    margin-top: 24px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    padding: 0px 24px;
    label {
        font-size: $primary-font-size;
        font-family: $font-primary;
        margin: 0;
        min-width: fit-content;
        margin-left: 12px;
    }
    input,
    textarea {
        background-color: transparent;
        width: 100%;
        border: none;
        font-size: $primary-font-size;
        font-family: $font-secondary;
    }
}

.secondary-highlight-box {
    height: 48px;
    border-radius: $primary-border-radius;
    margin-top: 24px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    padding: 0px 24px;
    label {
        font-size: $primary-font-size;
        font-family: $font-primary;
        margin: 0;
        min-width: fit-content;
        margin-left: 12px;
    }
    input,
    textarea {
        background-color: transparent;
        width: 100%;
        border: none;
        font-size: $primary-font-size;
        font-family: $font-secondary;
    }
}

.customInput {
    position: relative;
    min-width: 280px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 32px;
    &__label {
        position: absolute;
        left: 0;
        top: 5px;
        padding: 8px 20px;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms linear;
        font-family: "Asap";
        letter-spacing: 0.5px;
        font-weight: 400;
        line-height: 21px;
        font-size: 16px;
        margin-left: 5px;
        color: #b4b4b4;
    }
    &__field {
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 12px 20px;
        background: transparent;
        border-radius: 12px;
        height: 48px;
        font-size: 16px;
        &:focus,
        &:not(:placeholder-shown) {
            &+.customInput__label {
                transform: translate(0.5rem, -45%) scale(0.9);
                padding: 0px 20px;
                padding-left: 9px;
                top: 0
            }
        }
    }
}

// Search Filter
.search {
    position: relative;
    min-width: 225px;
    max-width: 480px;
    input[type='text'] {
        height: 48px;
        width: 100%;
        border: none;
        padding: 0px 24px;
        border-radius: 8px;
    }
    p {
        position: absolute;
        top: 13px;
        right: 33px;
        font-size: 15px;
        color: $primary-text-button-color;
        cursor: pointer;
    }
}

// Radio Buttons
.form-radio-buttons {
    label {
        font-size: $primary-font-size;
        font-family: $font-primary;
        margin: 0;
        margin-left: 12px;
        cursor: pointer;
    }
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        +.radio-label {
            &:before {
                content: '';
                border-radius: 100%;
                border: 1px solid $inactive-radio-button-color;
                display: inline-block;
                width: 1.4em;
                height: 1.4em;
                position: relative;
                top: 0;
                margin-right: 1em;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            +.radio-label {
                &:before {
                    background-color: $radio-button-color;
                    border: 1px solid $radio-button-color;
                    box-shadow: inset 0 0 0 4px $radio-button-bg-color;
                }
            }
        }
        &:focus {
            +.radio-label {
                &:before {
                    outline: none;
                    border-color: $radio-button-color;
                }
            }
        }
        &:disabled {
            +.radio-label {
                &:before {
                    box-shadow: inset 0 0 0 4px $inactive-radio-button-bg-color;
                    border-color: $inactive-radio-button-color;
                    background: $inactive-radio-button-color;
                }
            }
        }
        +.radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
}

// Toggle Buttons

/*
...Code structure should be like this for toggle button

<div class="toggle-wrapper loggedIn mx-2">
  <input type="checkbox" name="toggle" class="toggle">
  <label for="toggle" class="toggle-body">
    <span class="thumb"></span>
  </label>
</div>
*/

.toggle-wrapper {
    position: relative;
    width: 60px;
    border-radius: 25px;
    @media (max-width: 767px) {
        width: fit-content;
    }
    .toggle {
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        z-index: 3;
        position: absolute;
        top: 0;
        cursor: pointer;
    }
    .toggle-body {
        display: block;
        width: 40px;
        height: 20px;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 25px;
        background: $inactive-toggle-button-color;
        z-index: 1;
    }
    .toggle:checked+.toggle-body {
        background: $toggle-button-color;
    }
    .thumb {
        position: absolute;
        border-radius: 15px;
        padding: 0;
        top: 8px;
        left: 4px;
        width: 14px;
        height: 14px;
        background-color: #fff;
        background: -webkit-radial-gradient(10px 10px, circle cover, #fff);
        z-index: 2;
        margin-top: -5px !important;
    }
    .toggle:checked+.toggle-body .thumb {
        left: 20px;
    }
    .toggle:checked+.toggle-body .thumb:after {
        content: url('/assets/images/sidebar/light.png');
        width: 100%;
        height: 100%;
        top: -1px;
        left: -15px;
        border-radius: 15px;
        position: absolute;
    }
    .toggle+.toggle-body .thumb:after {
        content: url('/assets/images/sidebar/light.png');
        width: 100%;
        height: 100%;
        top: -2px;
        left: 20px;
        border-radius: 15px;
        position: absolute;
    }
}

// checkbox
// Use class 'customCheckbox'

.customCheckbox {
    display: block;
}

.customCheckbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:disabled{
        cursor: default;
    }
}

.customCheckbox label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
}

.customCheckbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
}

.customCheckbox input:checked+label:before {
    border: 2px solid $highlight-text-color;
}

.customCheckbox input:disabled+label:before, .customCheckbox input:disabled+label::after {
    cursor: default;
    opacity: 0.5;
}

.customCheckbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 13px;
    border: solid $highlight-text-color;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.line-map {
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        right: -49%;
    }
    .vl {
        content: "";
        display: inline-block;
        height: 0.8rem;
        vertical-align: bottom;
        width: 100%;
        margin-right: -10px !important;
        margin-left: -10px !important;
        margin-top: 26px;
    }
}

.chip {
    display: inline-flex;
    float: left;
    padding: 0px 15px;
    border-radius: 10px;
    margin-right: 10px;
}

// Text area
.customTextArea textarea{
    background-color: transparent;
    width: 100%;
    min-height: 48px;
    max-height: 120px;
    border-radius: 10px;
    margin-bottom: 32px;
    padding: 12px 24px;
    font-family: "Asap";
    letter-spacing: 0.5px;
    font-weight: 400;
    line-height: 21px;
    font-size: 16px;
    resize: none;
}

// Tabs
.stepper-tabs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .items{
        font-family: $font-secondary;
        font-size: $primary-font-size;

        &.active{
            color: $primary-text-button-color !important;
        }
    }
}

// Small Pagination

.new-small-pagination{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .item{
        font-family: $font-secondary;
        font-size: $primary-font-size;
        margin-right: 8px;
        &.box{
            width: 36px;
            height: 36px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            .fa{
                font-size: 20px;
                font-weight: 900;
            }

            &.active{
                cursor: pointer;
                .fa{
                    color: $primary-button-bg-color !important;
                }
            }
        }
    }
}

// Flex Gaps

.gap-4{
    gap: 4px !important;
}

.gap-8{
    gap: 8px !important;
}

.gap-12{
    gap: 12px !important;
}

.gap-16{
    gap: 16px !important;
}

.gap-20{
    gap: 20px !important;
}

.gap-24{
    gap: 24px !important;
}

// Dropdown Menu

.dropdown-menu{
    max-height: 400px;
    overflow: auto;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.30) !important;

    &::-webkit-scrollbar{
        width: 5px;
    }

    label{
        margin-bottom: 0;
    }
}